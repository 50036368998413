@tailwind base;

/* Your own custom base styles */
@tailwind components;


a:focus {
    outline-color: rgb(17, 24, 39);
}

/* Your own custom component styles */
.polka {
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23EFEFEF' fill-opacity='1' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='3'/%3E%3Ccircle cx='13' cy='13' r='3'/%3E%3C/g%3E%3C/svg%3E");
}

.prose-lg a {
    @apply transition-colors ease-out duration-200 border-b-2 hover:border-gray-500 border-gray-200 print:border-transparent pb-1 cursor-pointer;
}

.prose-lg strong {
    font-weight: 400;
    /* border-bottom: 5px solid rgb(252, 250, 175); */
    background-color: rgb(252, 250, 205);
    padding: 2px;
}

.prose-lg ul {
    @apply list-disc marker:text-gray-500;
}

.no-break {
    break-inside: avoid;
    page-break-inside: avoid;
}

.card-overflow {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    /* display: -webkit-box; */
}

::selection {
    @apply bg-gray-200;
}

.force-pring-backgrounds{
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;  /* Chrome/Safari/Edge/Opera */
    color-adjust: exact;
}

.timer-progress{
    background-image: -webkit-linear-gradient(right, var(--tw-gradient-to) var(--timer-ratio), var(--tw-gradient-from) var(--timer-ratio));
    background-image: -moz-linear-gradient(right, var(--tw-gradient-to) var(--timer-ratio), var(--tw-gradient-from) var(--timer-ratio));
    background-image: -o-linear-gradient(var(--tw-gradient-to) var(--timer-ratio), var(--tw-gradient-from) var(--timer-ratio));
    background-image: -ms-linear-gradient(right, var(--tw-gradient-to) var(--timer-ratio), var(--tw-gradient-from) var(--timer-ratio));
    background-image: linear-gradient(to right, var(--tw-gradient-to) var(--timer-ratio), var(--tw-gradient-from) var(--timer-ratio));
}


@media print {
    body {
    }
  }

@page {
    size: A3 portrait;
    margin: 10mm 9.5mm 20mm 10mm;
}


.minimal-scrollbars{
  scrollbar-width: thin;
  scrollbar-color: #aaa transparent;
  -ms-overflow-style: -ms-autohiding-scrollbar;

}

.minimal-scrollbars::-webkit-scrollbar-track  {
  background-color: transparent;
  border-radius: 10px;
}

.minimal-scrollbars::-webkit-scrollbar-thumb {
    border-radius: 10px;
}

.minimal-scrollbars::-webkit-scrollbar{
  //width: .3em;
  width: 10px;
    height: 10px;
}

@media(hover:hover){
  .minimal-scrollbars{
    scrollbar-color: transparent transparent;
  }
  .minimal-scrollbars:hover{
    scrollbar-color: #aaa transparent;
  }
  .minimal-scrollbars::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  .minimal-scrollbars:hover::-webkit-scrollbar-thumb {
    //background-color: #aaa;
    background: rgba(200,200,200, .8);
  }
}

/* Start purging... */
@tailwind utilities;
/* Stop purging. */
